import React, { Fragment } from 'react'
import SearchCfdiTraslado from 'modules/billing/components/SearchCfditraslado/searchCfdiTraslado'
import { ABanner } from 'shared/components/common'
import { Container } from 'reactstrap'

export default class ConsultarCfdi extends React.Component<PageProps> {
  render() {
    return (
      <>
        <Fragment>
          <ABanner title="Consultar CFDI:Traslado con carta porte" titleClassName="big-white" titleSize={10} />
          <Container className="mt-5">{<SearchCfdiTraslado />}</Container>
        </Fragment>
      </>
    )
  }
}
