import { BillingService } from 'modules/billing/services'
import React, { Component, Fragment } from 'react'
import { Col, Row, Table } from 'reactstrap'
import Container from 'reactstrap/lib/Container'
import { AButton, AInput } from 'shared/components/common'
import { SelectFormsy } from 'shared/components/formsy'
import { Manifest, SearchCfdiTrasladoState } from './types'
import Formsy from 'formsy-react'
const initialState: SearchCfdiTrasladoState = {
  error: false,
  codigo: '',
  operator: [],
  vehicle: [],
  selectVehicle: '',
  selectOperator: '',
  listManifest: [],
  errorGetManifest: '',
  validMessage: '',
  loadSuccess: false
}
export class SearchCfdiTraslado extends Component<any, SearchCfdiTrasladoState> {
  form: any
  state: SearchCfdiTrasladoState = initialState
  constructor(props: SearchCfdiTrasladoState) {
    super(props)
    this.setState(props)
  }
  componentDidMount() {
    this.getAll()
  }
  validCode(code: string) {
    const regex = /G[0-9]{4}[A-Z0-9]{6}/
    return regex.test(code)
  }
  async getAll() {
    try {
      const operadorData = await BillingService.getOperator()
      const vehicleData = await BillingService.getVehicles()
      if (operadorData.length > 0 && vehicleData.length > 0) {
        this.setState({ operator: operadorData, vehicle: vehicleData, error: false, codigo: '', loadSuccess: true })
      }
    } catch (error) {
      this.setState({ error: true })
      console.log('Error al obtener los datos de la carta porte: ', error)
    }
  }
  handleStateChangeOperator = (value: string) => {
    if (value) {
      this.setState({ selectOperator: value })
    }
  }
  handleStateChangeVehicle = (value: string) => {
    if (value) {
      this.setState({ selectVehicle: value })
    }
  }
  onValidForm = () => {
    console.log('')
  }

  onInvalidForm = () => {
    console.log('')
  }
  handleChange = (e: any) => {
    this.setState({ codigo: e.target.value })
  }
  onSubmit = async () => {
    try {
      let placa: string = this.state.selectVehicle.replace('-', '')
      placa = placa.replace('-', '')
      this.setState({ listManifest: [] })
      const manifestData: Manifest[] = await BillingService.getManifestCcp(placa, this.state.selectOperator)
      if (manifestData.length > 0) {
        this.setState({ listManifest: manifestData })
      } else if (manifestData.length === 0) {
        this.setState({ error: true, errorGetManifest: 'Lista se encuentra vacía' })
      } else {
        this.setState({ error: true, errorGetManifest: 'Error al obtener los cfdi:Consulte con su administrador de sistemas' })
      }
    } catch (error) {
      this.setState({ error: true, errorGetManifest: 'Error al obtener los cfdi:Consulte con su administrador de sistemas' })
    }
  }

  onSubmitCode = () => {
    if (this.state.codigo && this.validCode(this.state.codigo)) {
      window.open(`/cfdiCcpDigital/?code=${this.state.codigo}`, '_blank')
    } else {
      this.setState({ validMessage: 'Capture el codigo de rastreo correctamente' })
    }
  }

  render() {
    const { operator, vehicle, listManifest, errorGetManifest, codigo, validMessage, loadSuccess } = this.state

    let Search = (
      <Fragment>
        <Container>
          <Col>
            <h3>Consulta de cfdi por codigo de Rastreo(Carta porte)</h3>
            <AInput
              id="numcamion"
              name="numcamion"
              onChange={this.handleChange}
              placeholder="Código de reservación"
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', height: 50, border: '1px solid black', color: 'black' }}
              uppercase
              required
            />
            {validMessage ? <p style={{ color: 'red', fontSize: '12px' }}>{validMessage}</p> : null}
          </Col>

          <Col style={{ paddingTop: '10px' }}>
            <AButton onClick={this.onSubmitCode} disabled={!codigo} variant="pink">
              Consultar
            </AButton>
          </Col>
          <Formsy
            ref={(node: any) => {
              this.form = node
            }}
            onValidSubmit={this.onSubmit}
            onValid={this.onValidForm}
            onInvalid={this.onInvalidForm}
          >
            <h1>Consultar bitacora</h1>
            <Col>
              <h3>Selecciona el operador</h3>
              <SelectFormsy
                selectProps={{
                  className: 'custom-select'
                }}
                name="operatorSelect"
                className="mb-3"
                type="select"
                onChange={this.handleStateChangeOperator}
                value=""
                required
              >
                <option>seleccciona el operador</option>

                {operator.map(op => {
                  return (
                    <option key={op.id} value={op.rfc}>
                      {op.name}
                    </option>
                  )
                })}
              </SelectFormsy>
            </Col>
            <Col>
              <h3>Selecciona el autobus</h3>
              <SelectFormsy
                className="mb-3"
                name="vehicleSelect"
                selectProps={{
                  className: 'custom-select'
                }}
                onChange={this.handleStateChangeVehicle}
                value=""
                required
              >
                <option>seleccciona el autobus</option>
                {vehicle.map(vh => {
                  if (vh.work_type === '1') {
                    return (
                      <option key={vh.id} value={vh.plate_state}>
                        {vh.description}
                      </option>
                    )
                  }
                })}
              </SelectFormsy>
            </Col>
            <Col>
              <AButton variant="med">Consultar</AButton>
            </Col>
          </Formsy>
        </Container>
      </Fragment>
    )

    if (!loadSuccess) {
      Search = (
        <Container>
          <Col xs={10} sm={12}>
            <p>Para poder consultar </p>
          </Col>
          <Col xs={10} sm={5}>
            <AButton>
              {' '}
              <a href="/iniciarsesion" style={{ color: 'white' }}>
                Iniciar sesión
              </a>
            </AButton>
          </Col>
        </Container>
      )
    }
    return (
      <React.Fragment>
        <Container>
          {Search}
          {listManifest.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Sucursal de origen</th>
                  <th>Fecha de registro</th>
                  <th>Control</th>
                </tr>
              </thead>
              <tbody>
                {listManifest.map(man => {
                  return (
                    <tr key={man.tracking_code}>
                      <td>{man.tracking_code}</td>
                      <td>{man.branchOrigin}</td>
                      <td>{man.created_at}</td>
                      <td>
                        {' '}
                        <AButton variant="med">
                          <a href={`/cfdiCcpDigital/?code=${man.tracking_code}`} style={{ color: 'white' }} target="_blank">
                            Ver CFDI
                          </a>
                        </AButton>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          ) : (
            <Row>
              <Col>
                <h1 style={{ paddingLeft: '20px' }}>{errorGetManifest}</h1>
              </Col>
            </Row>
          )}
        </Container>
      </React.Fragment>
    )
  }
}

export default SearchCfdiTraslado
