import { ApiService } from 'core/services'
import { Manifest, Operator, Vehicle } from 'modules/billing/components/SearchCfditraslado/types'
import { CfdiLetterPorte, GenerateQrSatRequest } from './types'

class BillingService {
  public static instance: BillingService

  constructor() {
    if (BillingService.instance) {
      return BillingService.instance
    }
    BillingService.instance = this
  }

  getComplementLEtterPortData(letterPorte: string): Promise<CfdiLetterPorte[]> {
    return ApiService.get<CfdiLetterPorte[]>(`complementLetterPorte/getLetterPorteXML/${letterPorte}`)
  }

  generateQrSat(data: GenerateQrSatRequest) {
    const { timbreFiscalId, receptorRfc, emisorRfc, total, sello } = data
    const cadenaSello = sello.substr(sello.length - 8)
    return `https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?id=${timbreFiscalId}&re=${emisorRfc}&rr=${receptorRfc}&tt=${total}&fe=${cadenaSello}`
  }
  getComplementLetterPortDataCCP(letterPorte: string): Promise<CfdiLetterPorte[]> {
    return ApiService.get<CfdiLetterPorte[]>(`complementLetterPorte/web/appoperator/getcfdiletterporte/${letterPorte}`)
  }
  getOperator(): Promise<Operator[]> {
    return ApiService.get<Operator[]>(`employees/allOperator`)
  }
  getVehicles(): Promise<Vehicle[]> {
    return ApiService.get<Vehicle[]>(`vehicles`)
  }
  getManifestCcp(vehicle: string, operator: string): Promise<Manifest[]> {
    return ApiService.get<Manifest[]>(`complementLetterPorte/get/site/manifest/ccp/${vehicle}/${operator}`)
  }
  getCfdiManifestCcp(tracking_code: string): Promise<CfdiLetterPorte[]> {
    return ApiService.get<CfdiLetterPorte[]>(`complementLetterPorte/getcfdiletterporte/${tracking_code}`)
  }
}

const instance = new BillingService()

Object.freeze(instance)

export default instance
